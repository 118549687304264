body::-webkit-scrollbar {
  display: none;
}

body {
  --tw-bg-opacity: 1;
  /* background-color: rgb(64 66 63 / var(--tw-bg-opacity)); */
  background-color: #181818;
  color: white;
}

.bg-gray {
  --tw-bg-opacity: 1;
  /* background-color: rgb(105 105 105 / var(--tw-bg-opacity)); */
  background-color: #202020;
  border: #202020;
}

.bg-button {
  background: transparent linear-gradient(89deg, #0d0d0e 0%, #3b3838 50%, #0d0d0e 100%) 0% 0% no-repeat padding-box;
}

.bg-blue {
  background-color: #1145c2;
}

.font-weight-bold {
  font-weight: bold;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.text-grey {
  color: grey;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.border-radius-10 {
  border-radius: 10px;
}

.recent-img {
  background-size: cover;
  width: 200px;
  height: 80px;
}

a {
  /* color:white!important; */
  text-decoration: none !important;
}

.description-pad {
  background-color: #151b22;
  border: 1px solid grey;
  border-radius: 10px;
}

.green-font {
  color: #01fd82;
}

.green-border {
  border: 1px solid #198754;
  border-radius: 10px;
  width: fit-content;
}

.green-button {
  background-color: #198754;
  border: none;
}

.white-border-button {
  background-color: transparent;
  border: 1px solid white;
  width: 100%;
  border-radius: 10px;
}

.sold-button {
  width: 100%;
  background-color: transparent;
  border: 1px solid red;
  border-radius: 10px;
}

.navbar-light .navbar-toggler {
  border: 1px solid #181818 !important;
}

.modal-content {
  background-color: #181818 !important;
}

.modal-footer {
  border-top: none;
}

.metaverse-bg {
  padding-top: 50px;
  padding-bottom: 50px;
  background: url('../asset/metaverse_bg.png');
  background-size: cover;
  background-position-y: -400px;
}

.redeem-bg {
  /* background: url('../asset/redeem_bg.png'); */
  background-repeat: no-repeat;
  background-size: cover;
}

.modal-img {
  position: absolute;
  top: 100px;
  right: -300px;
}

.card {
  padding: 20px 10px 20px 10px;
  background-color: #313131 !important;
}

.category {
  padding: 10px;
  border-radius: 20px;
  /* background-color: #313131!important; */
}

.addr-btn {
  padding-right: 0px;
  background: transparent;
  border: none;
  color: white;
}

.btn-close {
  color: white !important;
}

.upcoming-font {
  position: absolute;
  right: 10px;
  bottom: -10px;
  border-radius: 10px;
}

#logo-desktop {
  width: 150px;
  height: 40px;
}

#time-badge {
  position: absolute;
  width: 100px;
  left: 50px;
  top: 20px;
  border-radius: 10px;
}

#cost-badge {
  position: absolute;
  width: 100px;
  right: 40px;
  top: 20px;
  border-radius: 10px;
}

#mint-count-badge {
  position: absolute;
  width: 100px;
  right: 10px;
  bottom: 10px;
  border-radius: 10px;
}

#mint-description {
  position: absolute;
  left: 40px;
  bottom: 20px;
}

#mint-badge {
  width: 140px;
  border-radius: 10px;
  cursor: pointer;
}

#recent-order {
  min-height: 45vh;
  max-height: 45vh;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.profile_img {
  width: 80px;
  border-radius: 50%;
}

.banner_left_img {
  width: 100%;
}

@media (max-Width: 769px) {
  .banner_left_img {
    width: 75%;
  }
}

.banner_right_img {
  width: 100%;
}

@media (max-Width: 769px) {
  .banner_right_img {
    width: 75%;
    margin-top: 50px;
  }
  .recent-img{
    display: none;
  }
}

.color-btn{
  background: linear-gradient(89deg, rgb(236, 98, 185) 0%, rgb(106, 128, 248) 100%) transparent;
}

.card{
  height: 100%;
}
.card-img-top{
  height: 100%;
}

.btn-purple{
  background-color: #752fbd;
  border: none;
}

.user-btn{
  background-color: transparent;
  border: 0px;
}
.bg-grey {
  background-color: #46474c!important;
}
.profile-pad{
  padding: 20px;
}

/* .dropdown-toggle::after{
  display: none;
} */

.text-green{
  color:#151b22;
}

.font-sm{
  font-size: 11px;
}

.white-border {
  border: 1px solid white;
  border-radius: 10px;
  width: fit-content;
}

.profile-button{
  background-color: #01fd82;
  border: none;
}

.w-50{
  width: 50%;
}

.book-card{
  width: 50%;
  margin: auto;
}

.coming-pad{
  padding-bottom: 500px;
}

.splide__pagination__page.is-active{
  background: rgb(12, 209, 12)!important;
}
.splide__pagination__page{
  background: #1144c2!important;
}

.border-none{
  border: 0px solid;
}

.beta-badge{
  font-size: small!important;
}

.transparent{
  background-color: transparent!important;
  border: none!important;
}

.book-badge{
  position: absolute!important;
}

.reduce-text{
  max-width: inherit;
  overflow: hidden!important;
  white-space: nowrap;
  text-overflow: ellipsis!important;
}

.new-book-pad {
  border: 2px solid grey;
  border-radius: 10px;
  background:#0b0b0c;
}

.new-book-title{
  background-color: black!important;
  border-radius: 10px;
}

canvas{
  height: 350px!important;
}