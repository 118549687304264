* {
  font-family: 'Satoshi', sans-serif;
}

body {
  background-color: #080b13 ! important;
}

header {
  justify-content: space-between;
  background-image: url("../../public/img/header_back.png");
  background-color: #17181b;
  background-size: cover;
  background-position: center;
  width: 100%;
}

div {
  color: white;
}

p {
  color: #afb3be;
  font-size: 18px;
}

a {
  color: #afb3be ! important;
  font-size: 18px;
}

nav {
  padding: 5px;
}

nav a {
  color: white ! important;
  text-decoration: none;
  padding: 15px 15px;
}

.banner-container {
  background-image: url("../../public/img/banner.png");
  /* background-color: #17181b; */
  background-size: cover;
  background-position: center;
  width: 100%;
}

#banner {
  max-width: 650px;
  height: 750px;
}

#banner .title-sm {
  color: #29b626;
  padding-top: 5.5rem;
}

#banner .title {
  font-size: 50px;
  font-weight: bold;
}

#banner .content {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#btn-dropdown {
  background-color: transparent;
  border-color: white;
  font-size: 18px;
  padding: .5rem 2rem;
  width: 100%;
}

.dropdown-item {
  color: black ! important;
  /* border-bottom: 1px solid #afafaf ! important; */
}

#part-2 {
  background-color: #17181b;
}

#btn-signin {
  background-color: #29b626;
  color: white;
  padding: .5rem 2rem;
}

#btn-signin img {
  width: 20px;
  height: auto;
}

.logo img {
  height: 30px;
}

.logo:hover {
  cursor: pointer;
}

#btn-gotoBookstore {
  background-color: #1244c2;
  color: white;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.7rem 2rem;
}

#btn-gotoBookstore img {
  width: 20px;
  height: 20px;
}

#btn-watchVideo {
  background-color: #20273b;
  color: white;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.7rem 2rem;
}

#btn-watchVideo img {
  width: 30px;
  height: 30px;
  margin-right: .5rem;
}

#section-recent-drops .title {
  font-size: 30px;
}

.card-container {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-custom {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.card-custom img {
  max-width: 200px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 1rem;
}

.card-content {
  position: relative;
  top: -60px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-content .tag-name {
  font-weight: bold;
  color: white ! important;
}

.card-content .tag-author {
  font-size: 14px;
  color: #ffffffdd;
}

.card-content .tag-price {
  color: #29b626;
  font-weight: bold;
}

.color-rectangle {
  width: 100%;
  min-height: 120px;
  border-radius: 10px;
}

#section-upcoming-releases {
  position: relative;
  min-height: 500px;
  padding-top: 5rem;
}

#section-upcoming-releases .background {
  /* background-color: #403f67; */
  width: 100%;
  min-height: 442px;
  border-radius: 50px;
  position: absolute;
  z-index: -1;

  justify-content: space-between;
  background-image: url("../../public/flow-bg.png");
  background-size: cover;
  background-position: center;
}

#section-upcoming-releases .content {
  text-align: center;
  padding-top: 3rem;
}

#section-upcoming-releases #carousel-control-group {
  position: absolute;
  top: 70%;
}

.margin-carousel-button {
  margin-right: 180px;
}

.section {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.section-title,
.title {
  font-size: 3rem;
  font-weight: bold;
}

.section-description {
  font-size: 1.2rem;
  max-width: 700px;
  text-align: center;
}

#section-featured-drops {
  background-color: #080b13;
}

.drop-item-container {
  width: 100%;
}

.drop-item {
  background-color: #17181b;
  min-height: 100px;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: .5rem;
}

.drop-item .drop-img {
  display: flex;
  justify-content: center;
}

.drop-item .drop-img img {
  width: 100px;
  height: auto;
  border-radius: 0px;
}

@media (max-width: 991px) {
  .drop-item .drop-img img {
    max-width: 200px;
  }

  .drop-item .drop-content {
    align-items: center;
  }

  .drop-item .btn-container {
    align-items: center;
  }

  #banner .title,
  .title,
  .section-title {
    font-size: 30px;
    font-weight: bold;
  }

  #section-about .play-video {
    width: 100% ! important;
  }

  #nav-helpful {
    width: 100% ! important;
  }
}

.drop-item .drop-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.drop-item .drop-content .title {
  font-size: 24px ! important;
  margin-right: 1rem;
  font-weight: bold;
}

.drop-item .drop-content .badge {
  background-color: #19281c;
  color: #29b626;
  font-size: 14px;
}

.drop-item .drop-price {
  color: #29b626;
  display: flex;
  align-items: center;
  min-width: 10%;
  justify-content: center;
  font-weight: bold;
  padding-top: 1rem;
  padding-bottom: 1rem;
}


.drop-item .btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


.drop-item .btn-available {
  background-color: #1244c2;
  color: white;
  padding: 10px 30px ! important;
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.drop-item .btn-available img {
  width: 20px;
  height: 20px;
  margin-left: .5rem;
}

.drop-item .btn-available:hover {
  background-color: #092877;
}

.btn-view-all {
  background-color: #101521 ! important;
  padding: 10px 30px ! important;
  color: white ! important;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 1.0rem;
}

.mr-3 {
  margin-right: 1.5rem;
}

.mr-4 {
  margin-right: 2rem;
}

#section-about {
  height: 720px;
  position: relative;
  display: flex;
  background-image: url("../../public/img/video_back.png");
  background-size: cover;
}

#section-about img {
  max-width: 100%;
  position: absolute;
  z-index: -1;
  height: 100%;
}

#section-about .content {
  display: flex;
  height: 100%;
  flex-direction: column;
  max-width: 600px;
  justify-content: center;
  align-items: flex-start;
}

#section-about .content .logo {
  margin-bottom: 2rem;
  z-index: 0;
}

#section-about .content .logo img {
  width: 200px;
  height: auto;
}

#section-about .content .title {
  font-weight: bold;
}

#section-about .content .title-sm {
  font-size: 20px;
  color: #29b626;
}

#section-about .content .title-sm img {
  width: 20px;
  height: 20px;
}

#section-about .content .title {
  font-size: 36px;
}

#section-about .content .description {
  font-size: 20px;
}

#section-about .play-video {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#section-about .play-video img {
  width: 500px;
  height: auto;
}

.contact-box {
  min-height: 500px;
  padding: 2rem ! important;
}

.contact-box .logo {
  width: 100px;
  height: auto;
  margin-bottom: 2rem ! important;
}

.contact-box .title {
  font-size: 20px;
  padding-top: 1rem;
}

.form-send-email {
  display: flex;
  flex-direction: column;
}

.form-send-email div {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.form-send-email input {
  margin-bottom: 2rem;
  font-size: 18px;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.form-send-email button {
  background-color: #29b626;
  color: white;
  transition: background-color ease-in 100ms;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.form-send-email button img {
  width: 20px;
  height: auto;
  margin-left: .5rem;
}

.form-send-email button:hover {
  background-color: #176b16;
  color: white;
}

footer {
  background-color: #17181b;
}

#footer-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#footer-right .meta-data {
  font-size: 12px;
}

#nav-item-group {
  display: flex;
  border-bottom: 1px solid #888;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.nav-item {
  color: white;
  text-decoration: none;
  transition: color ease-in 100ms;
  padding: .5rem;
}

.nav-item:hover {
  color: #888;
}

.nav-item-title {
  font-size: 20px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

#nav-helpful {
  display: flex;
  flex-direction: column;
  width: 500px;
}

#nav-partner-with-us {
  display: flex;
  flex-direction: column;
}

.nav-logo-item {
  width: 40px;
  height: 40px;
}

.nav-logo-item img {
  width: auto;
  height: 30px;
}

#nav-group {
  display: flex;
  padding-top: 2rem;
}

#nav-logo-container {
  line-height: 60px;
}


.social-link i {
  color: #888;
  font-size: 20px;
}

#carousel-upcoming {
  /* min-height: 500px; */
  width: 100%;
}

#carousel-upcoming .item {
  width: 200px;
  height: auto;
  border-radius: 10px;
}

#carousel-upcoming .focus {
  scale: 1.5;
}

.carousel-container .center-item {
  transform: scale(1.5);
  transition: transform 0.5s ease;
}

.center-item {
  transform: scale(1.5);
  /* Adjust the scale factor as needed */
  transition: transform 0.5s ease;
  /* Add transition for smooth scaling effect */
}

.react-multi-carousel-dot-list {
  display: none !important;
}

.react-multi-carousel-list {
  position: relative;
  padding-bottom: 150px;
}

.promo-video {
  width: 100%;
  height: 400px;
}

#dropdown-basic {
  background: transparent;
  border: none;
  padding-left: 16px;
}

#dropdown-mobile{
  background: #101521!important;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1244c2!important;
}

